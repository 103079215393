import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Independence from "../components/PageComponents/Independence"
import localize from "../components/utils/localize.js"

const IndexPage = ({ data, location, pageContext }) => (
  <Layout location={location} context={pageContext}>
    <SEO title="Independence" />
    <Independence images={data.allFile.edges} sanity={data} />
  </Layout>
)

export default localize(IndexPage)

export const pageQuery = graphql`
  query IndependencePageQuery {
    allFile(filter: { relativeDirectory: { eq: "Home" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 3080, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    thumbnail: file(name: { eq: "Artboard-1" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sanityHomePage {
      exploreSectionTitle {
        _type
        en
        tc
      }
      exploreSectionParagraph {
        _type
        en
        tc
      }
      formTitle {
        _type
        en
        tc
      }
      howToJoin {
        _type
        en
        tc
      }
      howToJoinStepOne {
        _type
        en
        tc
      }
      howToJoinStepThree {
        _type
        tc
        en
      }
      howToJoinStepTwo {
        _type
        en
        tc
      }
      joinSectionParagraph {
        _type
        en
        tc
      }
      joinSectionTitle {
        _type
        en
        tc
      }
      submit {
        _type
        en
        tc
      }
      adventureTitle {
        _type
        en
        tc
      }
      embrace {
        _type
        en
        tc
      }
      freedomTitle {
        _type
        en
        tc
      }
      independenceTitle {
        _type
        en
        tc
      }
      inspirationTitle {
        _type
        en
        tc
      }
      progressTitle {
        _type
        en
        tc
      }
      wisdomTitle {
        _type
        tc
        en
      }
    }
  }
`
