import React from "react"
import Img from "gatsby-image"

export default function Post({
  openSlider,
  images,
  isChinese,
  enTitle,
  cnTitle,
  enText,
  cnText,
  enFull,
  cnFull,
}) {

  return (
    <article
      className="independence-slider__slide px-10 xl:px-4 md:px-0 md:text-center mb-24 cursor-pointer"
      onClick={() => {
        openSlider({ images, enTitle, cnTitle, enFull, cnFull })
      }}
    >
      <div className="independence-slider__thumbnail">
        <Img fluid={images[0]} />
      </div>
      {/* <h5 className="body mt-10 mb-6">{isChinese ? cnTitle : enTitle}</h5>
      <p className="small-copy">{isChinese ? cnText : enText}</p> */}
    </article>
  )
}
