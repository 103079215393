import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import styles from "./index.module.scss"
import { StaticQuery, graphql } from "gatsby"
import "./index.scss"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import CTABtn from "../../Button/CTA"
import IGSlider from "./IGSlider"
import Post from "./Post.js"

export default function Indepedence({ sanity }) {
  const [sliderIsActive, setSliderIsActive] = useState(false)
  const [numOfPostsShowing, setNumOfPostsShowing] = useState(9)
  const [allPostsAreShown, setAllPostsAreShown] = useState(false)
  const [totalNumOfPosts, setTotalNumOfPosts] = useState(0)
  const postContainer = useRef(null)
  const [postContent, setPostContent] = useState({
    images: [],
    cnTitle: "",
    enTitle: "",
    enContent: "",
    cnContent: "",
  })
  const isChinese = useSelector(state => state.lang.locale) === "tc"

  // const swipeConfig = {
  //   delta: 10,
  //   preventDefaultTouchmoveEvent: false,
  //   trackTouch: true,
  //   trackMouse: false,
  //   rotationAngle: 0,
  // }

  const openSlider = content => {
    setSliderIsActive(true)
    setPostContent(content)
  }

  const closeSlider = () => {
    setSliderIsActive(false)
  }


  const showMorePosts = () => {
    if (totalNumOfPosts && numOfPostsShowing < totalNumOfPosts) {
      const currentNumOfPostsShowing = numOfPostsShowing
      setNumOfPostsShowing(currentNumOfPostsShowing + 30)
    }
  }

  useEffect(() => {
    if(postContainer.current){
      setTotalNumOfPosts(postContainer.current.children.length)
    }
    if(numOfPostsShowing < totalNumOfPosts){
      setAllPostsAreShown(false)
    } else {
      setAllPostsAreShown(true)
    }

    const postArray = postContainer.current
      ? [...postContainer.current.children]
      : null
    if (postArray) {
      postArray.map((post, i) => {
        const dataOrder = post.getAttribute('data-order')
        if(!dataOrder){
          post.setAttribute("data-order", i + 1)
        }
        if(dataOrder && dataOrder <= numOfPostsShowing){
          post.classList.remove('hidden', 'invisible', 'opacity-0')
        }
      })
    }
  })

  return (
    <div>
      <section className={`pt-64 pb-16 xxl:pb-32 ${styles.sectionHeader}`}>
        <header className="text-center">
          {isChinese ? (
            <h2 className="hero light-font mb-10">回顧#MYBMWDAY媒體牆</h2>
          ) : (
            <h2 className="hero light-font mb-10">
              THROWBACK TO #MYBMWDAY <br /> SOCIAL WALL
            </h2>
          )}

          <div className="flex justify-center">
            <div className="xxl:w-6/12 md:w-full w-8/12">
              {isChinese ? (
                <>
                  <p className="large-text light-font md:px-12 md:mt-4 ">
                    回顧您與BMW的感動時刻，並於Instagram或Facebook分享相片或影片並標註
                    #MYBMWDAY，即有機會被轉載至BMWHK網站及社交媒體，以及贏取BMW獨家禮品。
                  </p>
                </>
              ) : (
                <p className="large-text light-font md:px-12 md:mt-4">
                  Throwback to your BMW moments and use our hashtag #MYBMWDAY to
                  tag your post on Instagram or Facebook for a chance to be
                  featured on our website and social media. Selected winners may
                  receive exclusive BMW gift. Join us NOW!
                </p>
              )}
            </div>
          </div>
        </header>
      </section>
      <section className="slider article-container xxl:py-40 py-20 overflow-hidden">
        <StaticQuery
          query={graphql`
            query IndependenceSliderQuery {
              post1: file(name: { eq: "post-1" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post2: file(name: { eq: "post-2" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post3: file(name: { eq: "post-3" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post4: file(name: { eq: "post-4" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post5: file(name: { eq: "post-5" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post6: file(name: { eq: "post-6" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post7: file(name: { eq: "post-7" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post7b: file(name: { eq: "post-7b" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post8: file(name: { eq: "post-8" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post9: file(name: { eq: "post-9" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post10: file(name: { eq: "post-10" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post11: file(name: { eq: "post-11" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              post12: file(name: { eq: "post-12" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              ed1: file(name: { eq: "exploration-day-1" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              ed2: file(name: { eq: "exploration-day-2" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              fd1: file(name: { eq: "freedom-day-1" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              fd2: file(name: { eq: "freedom-day-2" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              gbd1: file(name: { eq: "giving-back-day-1" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              gbd2: file(name: { eq: "giving-back-day-2" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              oct1: file(name: { eq: "MYBMWDAY_Oct_FBK_Daniel-Lee" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              oct2: file(name: { eq: "MYBMWDAY_Oct_FBK_KH-Raph" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              oct3: file(name: { eq: "MYBMWDAY_Oct_IG_eric_118i" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              oct4: file(name: { eq: "MYBMWDAY_Oct_IG_invisible_k_concept" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              oct5: file(name: { eq: "MYBMWDAY_Oct_IG_kkckelly" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              oct6: file(name: { eq: "MYBMWDAY_Oct_IG_leolee4314" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              oct7: file(name: { eq: "MYBMWDAY_Oct_IG_marcoautomotive" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan1: file(name: { eq: "MYBMWDAY_IG_BlackJackF20Lci_1" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan1b: file(name: { eq: "MYBMWDAY_IG_BlackJackF20Lci_2" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan1c: file(name: { eq: "MYBMWDAY_IG_BlackJackF20Lci_3" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan1d: file(name: { eq: "MYBMWDAY_IG_BlackJackF20Lci_4" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan1e: file(name: { eq: "MYBMWDAY_IG_BlackJackF20Lci_5" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan2: file(name: { eq: "MYBMWDAY_IG_frosty" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan3: file(name: { eq: "MYBMWDAY_FBK_Chau-Chun-Fai" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan4: file(name: { eq: "MYBMWDAY_IG_oneproductionhk" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan5: file(name: { eq: "MYBMWDAY_IG_Seafood_guai" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan6: file(name: { eq: "MYBMWDAY_IG_ronnett_photography_hk" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              jan7: file(name: { eq: "MYBMWDAY_FBK_Martin-Chu" }) {
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="md:px-10 xl:px-56 md:pb-40 relative px-40">
              <div
                className="flex flex-wrap mb-20"
                ref={postContainer}
              >
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.jan1.childImageSharp.fluid, data.jan1b.childImageSharp.fluid, data.jan1c.childImageSharp.fluid, data.jan1d.childImageSharp.fluid, data.jan1e.childImageSharp.fluid]}
                    enTitle="😎😎😎"
                    cnTitle="😎😎😎"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3o3JMWz' target='_blank'/>https://bit.ly/3o3JMWz</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3o3JMWz' target='_blank'/>https://bit.ly/3o3JMWz</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.jan2.childImageSharp.fluid]}
                    enTitle="Rolling Into The Concrete Jungle 🚦"
                    cnTitle="Rolling Into The Concrete Jungle 🚦"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/34V6Vmm' target='_blank'/>https://bit.ly/34V6Vmm</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/34V6Vmm' target='_blank'/>https://bit.ly/34V6Vmm</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.jan3.childImageSharp.fluid]}
                    enTitle=""
                    cnTitle=""
                    enFull="<p className='mb-10'>Reposted From: <a href='http://bit.ly/3rCxu9T' target='_blank'/>http://bit.ly/3rCxu9T</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='http://bit.ly/3rCxu9T' target='_blank'/>http://bit.ly/3rCxu9T</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.jan4.childImageSharp.fluid]}
                    enTitle="BMW G20 320i ALL THE STAR"
                    cnTitle="BMW G20 320i ALL THE STAR"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3hD94Ze' target='_blank'/>https://bit.ly/3hD94Ze</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3hD94Ze' target='_blank'/>https://bit.ly/3hD94Ze</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.jan5.childImageSharp.fluid]}
                    enTitle=""
                    cnTitle=""
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/34WTXEB' target='_blank'/>https://bit.ly/34WTXEB</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/34WTXEB' target='_blank'/>https://bit.ly/34WTXEB</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.jan6.childImageSharp.fluid]}
                    enTitle="BMW M135 in autumn 🍂"
                    cnTitle="BMW M135 in autumn 🍂"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3aSJoX6' target='_blank'/>https://bit.ly/3aSJoX6</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3aSJoX6' target='_blank'/>https://bit.ly/3aSJoX6</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.jan7.childImageSharp.fluid]}
                    enTitle=""
                    cnTitle=""
                    enFull="<p className='mb-10'>Reposted From: <a href='http://bit.ly/3aTz6G3' target='_blank'/>http://bit.ly/3aTz6G3</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='http://bit.ly/3aTz6G3' target='_blank'/>http://bit.ly/3aTz6G3</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.oct1.childImageSharp.fluid]}
                    enTitle="Picnic Day !"
                    cnTitle="Picnic Day !"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/2UY6X7L' target='_blank'/>https://bit.ly/2UY6X7L</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/2UY6X7L' target='_blank'/>https://bit.ly/2UY6X7L</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.oct2.childImageSharp.fluid]}
                    enTitle="BMW made my day."
                    cnTitle="BMW made my day."
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/372hLat' target='_blank'/>https://bit.ly/372hLat</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/372hLat' target='_blank'/>https://bit.ly/372hLat</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.oct3.childImageSharp.fluid]}
                    enTitle=""
                    cnTitle=""
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3nRd8GZ' target='_blank'/>https://bit.ly/3nRd8GZ</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3nRd8GZ' target='_blank'/>https://bit.ly/3nRd8GZ</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.oct4.childImageSharp.fluid]}
                    enTitle="Click “❤” if you like it."
                    cnTitle="Click “❤” if you like it."
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3m1H3f5' target='_blank'/>https://bit.ly/3m1H3f5</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3m1H3f5' target='_blank'/>https://bit.ly/3m1H3f5</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.oct5.childImageSharp.fluid]}
                    enTitle=""
                    cnTitle=""
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3pUTqfm' target='_blank'/>https://bit.ly/3pUTqfm</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3pUTqfm' target='_blank'/>https://bit.ly/3pUTqfm</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.oct6.childImageSharp.fluid]}
                    enTitle="G30."
                    cnTitle="G30."
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3q00EyW' target='_blank'/>https://bit.ly/3q00EyW</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3q00EyW' target='_blank'/>https://bit.ly/3q00EyW</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.oct7.childImageSharp.fluid]}
                    enTitle="Autumn has come"
                    cnTitle="Autumn has come"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3lnNmIH' target='_blank'/>https://bit.ly/3lnNmIH</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3lnNmIH' target='_blank'/>https://bit.ly/3lnNmIH</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post7.childImageSharp.fluid,data.post7b.childImageSharp.fluid]}
                    enTitle="Explore the undiscovered."
                    cnTitle="Explore the undiscovered."
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3d4lFCm' target='_blank'/>https://bit.ly/3d4lFCm</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3d4lFCm' target='_blank'/>https://bit.ly/3d4lFCm</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post8.childImageSharp.fluid]}
                    enTitle="Overcome all kinds of roads."
                    cnTitle="Overcome all kinds of roads."
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/34u2ZYL' target='_blank'/>https://bit.ly/34u2ZYL</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/34u2ZYL' target='_blank'/>https://bit.ly/34u2ZYL</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post9.childImageSharp.fluid]}
                    enTitle="香港嶼南好風景"
                    cnTitle="香港嶼南好風景"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/30Btx9r' target='_blank'/>https://bit.ly/30Btx9r</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/30Btx9r' target='_blank'/>https://bit.ly/30Btx9r</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post10.childImageSharp.fluid]}
                    enTitle="Rain night"
                    cnTitle="Rain night"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/34qz2sJ' target='_blank'/>https://bit.ly/34qz2sJ</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/34qz2sJ' target='_blank'/>https://bit.ly/34qz2sJ</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post11.childImageSharp.fluid]}
                    enTitle="Can't believe this was one year ago already. What a good start of a day with Mandy!"
                    cnTitle="Can't believe this was one year ago already. What a good start of a day with Mandy!"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/2GG9Iqs' target='_blank'/>https://bit.ly/2GG9Iqs</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/2GG9Iqs' target='_blank'/>https://bit.ly/2GG9Iqs</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post12.childImageSharp.fluid]}
                    enTitle="#MYBMWDAY BMW Hong Kong"
                    cnTitle="#MYBMWDAY BMW Hong Kong"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/34xlnjq' target='_blank'/>https://bit.ly/34xlnjq</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/34xlnjq' target='_blank'/>https://bit.ly/34xlnjq</a></p><p className='mb-10'>#MYBMWDAY #BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post1.childImageSharp.fluid]}
                    enTitle="BMW Hong Kong #MYBMWDAY"
                    cnTitle="BMW Hong Kong #MYBMWDAY"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/33MTxBb' target='_blank'/>https://bit.ly/33MTxBb</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/33MTxBb' target='_blank'/>https://bit.ly/33MTxBb</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post3.childImageSharp.fluid]}
                    enTitle="Morning coffee is extremely important for the most beautiful roadster!"
                    cnTitle="Morning coffee is extremely important for the most beautiful roadster!"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3gU0LH5' target='_blank'/>https://bit.ly/3gU0LH5</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3gU0LH5' target='_blank'/>https://bit.ly/3gU0LH5</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post2.childImageSharp.fluid]}
                    enTitle="BMW Hong Kong #MYBMWDAY"
                    cnTitle="BMW Hong Kong #MYBMWDAY"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/3gXKMHW' target='_blank'/>https://bit.ly/3gXKMHW</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/3gXKMHW' target='_blank'/>https://bit.ly/3gXKMHW</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post4.childImageSharp.fluid]}
                    enTitle="Sunday Morning Drive"
                    cnTitle="Sunday Morning Drive"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/33HAvvO' target='_blank'/>https://bit.ly/33HAvvO</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/33HAvvO' target='_blank'/>https://bit.ly/33HAvvO</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post5.childImageSharp.fluid]}
                    enTitle="猜猜在那裡？"
                    cnTitle="猜猜在那裡？"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/2ECmDZp' target='_blank'/>https://bit.ly/2ECmDZp</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/2ECmDZp' target='_blank'/>https://bit.ly/2ECmDZp</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[data.post6.childImageSharp.fluid]}
                    enTitle="Running in the rain"
                    cnTitle="Running in the rain"
                    enFull="<p className='mb-10'>Reposted From: <a href='https://bit.ly/2DYxIDW' target='_blank'/>https://bit.ly/2DYxIDW</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                    cnFull="<p className='mb-10'>原文： <a href='https://bit.ly/2DYxIDW' target='_blank'/>https://bit.ly/2DYxIDW</a></p><p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW</p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[
                      data.ed1.childImageSharp.fluid,
                      data.ed2.childImageSharp.fluid,
                    ]}
                    enTitle="Exploring the City – #MYBMWDAY"
                    cnTitle="探索城市故事 #MYBMWDAY"
                    enText="You don't have to travel far to explore new frontiers. There's so much to discover in your own city, if you just get in your car and drive somewhere new."
                    cnText="這城市太多有待您發現的地點！回想駕著愛車隨意闖盪，無需遠征他國探索新事物的日子。"
                    enFull="<p className='mb-10'>You don't have to travel far to explore new frontiers. There's so much to discover in your own city, if you just get in your car and drive somewhere new.</p>
                    <p className='mb-10'>From modern architecture, to historical sights, to Instagram hotspots — just open up your mind and eyes. Throwing back to the days where you see there's so much to explore in Hong Kong. </p>
                    <p className='mb-10'>Tell us about somewhere new you've recently discovered! Share your day with BMW, tag #MYBMWDAY and Join the Hashtag campaign NOW! </p>
                    <p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW </p>"
                    cnFull="<p className='mb-10'>這城市太多有待您發現的地點！回想駕著愛⾞隨意闖盪，無需遠征他國探索新事物的日子。 無論現代建築或歷史名勝，還是最新鮮的Instagram拍照熱點，都能刷新您對香港的認識，刺激您對這城的想像。</p>
                    <p className='mb-10'>與我們分享您的最新探索地點吧!分享您的⼀天，並標籤 #MYBMWDAY，參參加 #MYBMWDAY Hashtag活動！</p>
                    <p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW </p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[
                      data.fd1.childImageSharp.fluid,
                      data.fd2.childImageSharp.fluid,
                    ]}
                    enTitle="Enjoy the Freedom – #MYBMWDAY"
                    cnTitle="享受寧靜的美好 #MYBMWDAY"
                    enText="Reconnect with the great outdoors for a sense of freedom. There's nothing more freeing than a breath of fresh air and the feeling of a cool breeze on your face as you drive with the windows down on an open road."
                    cnText="遠離煩囂，擺脫城市束縛，尋回⾃自由感覺。回望在⼈煙罕至的郊外驅車享樂，搖下⾞窗感受涼風撫面的⾃由感覺，重拾悠然⾃在樂趣。"
                    enFull="<p className='mb-10'>Reconnect with the great outdoors for a sense of freedom.
                    There's nothing more freeing than a breath of fresh air and the feeling of a cool breeze on your face as you drive with the windows down on an open road.</p>
                    <p className='mb-10'>Tell us how you embrace freedom! Share your day with BMW, tag #MYBMWDAY and join the Hashtag campaign NOW!</p>
                    <p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW </p>"
                    cnFull="<p className='mb-10'>遠離煩囂，擺脫城市束縛，尋回⾃自由感覺。
                    回望在⼈煙罕至的郊外驅車享樂，搖下⾞窗感受涼風撫面的⾃由感覺，重拾悠然⾃在樂趣。</p>
                    <p className='mb-10'>與我們分享您如何度過「遨翔自得日」!分享您的一天，並標籤 #MYBMWDAY，參加 #MYBMWDAY Hashtag活動!</p>
                    <p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW </p>"
                  />
                </div>
                <div className="md:px-2 invisible hidden w-4/12 mb-20 transition-all duration-300 ease-out opacity-0">
                  <Post
                    openSlider={openSlider}
                    isChinese={isChinese}
                    images={[
                      data.gbd1.childImageSharp.fluid,
                      data.gbd2.childImageSharp.fluid,
                    ]}
                    enTitle="Giving Back to the Community– #MYBMWDAY"
                    cnTitle="摯誠回饋社區 #MYBMWDAY"
                    enText="The most rewarding thing you can do with your free time is to give back to your community and help those around you in need."
                    cnText="運⽤用閑暇回饋社區及幫助身邊有需要的人，是最有滿⾜感的事情。坐言起行，駕車運送補給品、食糧或醫療用品，捐贈⾄至您心儀的慈善機構，為您的社區出一分力吧!"
                    enFull="<p className='mb-10'>The most rewarding thing you can do with your free time is to give back to your community and help those around you in need.</p>
                    <p className='mb-10'>Why not join your local charity in helping with some community work? Load up your car and deliver supplies, food or medical gear to a charity you believe in. </p>
                    <p className='mb-10'>How do you like to give back? Share your story with BMW, tag #MYBMWDAY and join the Hashtag campaign NOW! </p>
                    <p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW </p>"
                    cnFull="<p className='mb-10'>運⽤用閑暇回饋社區及幫助身邊有需要的人，是最有滿足感的事情。</p>
                    <p className='mb-10'>坐言起行，駕車運送補給品、食糧或醫療用品，捐贈⾄至您心儀的慈善機構，為您的社區出一分力吧！</p>
                    <p className='mb-10'>您會如何回饋社區?與我們分享您的一天，並標籤 #MYBMWDAY，⾺上參加 #MYBMWDAY Hashtag活動！</p>
                    <p className='mb-10'>#BMWHK #BMWHONGKONG #ALONGWITHBMW </p>"
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  className={`${
                    allPostsAreShown
                      ? "opacity-0 invisible"
                      : "opacity-1 visible"
                  } block px-20 py-4 border-black border mb-16 text-center uppercase hover:bg-white hover:text-black transition-all ease-linear duration-200`}
                  onClick={showMorePosts}
                >
                  {isChinese ? "發掘更多" : "Show More"}
                </button>
              </div>
            </div>
          )}
        />
      </section>
      <section className="md:px-12 md:py-20 py-40">
        <div className="flex justify-center">
          <div className="md:w-full w-8/12">
            {isChinese ? (
              <div className="terms text-2xl">
                <h5 className="title mb-20 text-center">條款及細則:</h5>
                <ul className="list">
                  <li className="item flex">
                    <span className="mr-4">1.</span>
                    <span>
                      你一旦根據以下提及之步驟選擇參與 “#MYBMWDAY” Facebook and
                      Instagram
                      Hashtag活動（「本活動」），即表示你已閲讀、明白並同意遵守本活動的條款及條件（「本條款及條件」）。
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">2.</span>
                    <span>
                      於每張作品標籤(Hashtag) 「#MYBMWDAY」並提及@BMWHONGKONG。
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">3.</span>
                    <span>
                      你必須將你的Instagram賬號設定為公開賬號或你的Facebook作品設定必須為公開。
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">4.</span>
                    <span>
                      每個Instagram和Facebook賬號參加本活動次數不限。每位被選中的參加者於本活動最多只可贏取一份獎品。
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">5.</span>
                    <span>
                      BMWHK將從參加者作品中選岀作品，而獲選作品將獲得BMWHK限定紀念品一份。被選岀作品亦將會被轉載至BMWHK社交平台及網站。
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">6.</span>
                    <span>獎品先到先得，送完即止。</span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">7.</span>
                    <span>
                      所有作品一經於本活動提交予BMWHK，即你授予BMWHK不可撤回及永久專用的許可以使用、編輯、修改或複製提交的作品作任何用途，包括但不限於在公開場所及/或刊物及印刷品、網頁、其他電子媒體及其他BMWHK認可的方式，不論在香港或其他地區，進行出版、印刷、展示、陳列、宣傳推廣、發佈或其他BMWHK認為合適之用途，而無須事先通知你或徵求你的同意或提供任何彌償。
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">8.</span>
                    <span>
                      寶馬汽車(香港)有限公司保留隨時終止優惠及修改其條款及細則之權利而無須另行通知。
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">9.</span>
                    <span>
                      如有任何爭議，寶馬汽車(香港)有限公司保留最終決定權。
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="terms text-2xl">
                <h5 className="title mb-20 text-center">
                  Terms &amp; Conditions
                </h5>
                <ul className="list">
                  <li className="item flex">
                    <span className="mr-4">1.</span>
                    <span>
                      Upon choosing to participate in the “#MYBMWDAY” Facebook
                      and Instagram Hashtag Campaign according to the steps as
                      mentioned below, you are deemed to have read, accepted and
                      agreed to be bound by these terms and conditions (“Terms
                      and Conditions”).
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">2.</span>
                    <span>
                      Tag each Entry with hashtags “#MYBMWDAY”, and mention
                      “@BMWHONGKONG” on each Entry.
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">3.</span>
                    <span>
                      You must set your Instagram account/ Facebook post entry
                      as public.
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">4.</span>
                    <span>
                      Each Instagram and Facebook account is eligible to
                      participate in this Campaign more than once. Each person
                      can get 1 Prize only (if eligible).
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">5.</span>
                    <span>
                      BMWHK will choose the selected entries and participants
                      will receive a limited edition gift. Selected entries
                      might be reposted on official BMWHK social media platform
                      and website.
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">6.</span>
                    <span>
                      Prize is awarded on a first-come, first-served basis, and
                      available while stock lasts.
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">7.</span>
                    <span>
                      By submitting an Entry in the Campaign to BMWHK, you have
                      given an irrevocable perpetual license to BMWHK to use,
                      edit, modify or copy the submitted Entry for any purposes,
                      including but not limited to publish, print, exhibit,
                      display, promote, issue or any other purposes as BMWHK
                      considers appropriate, whether in Hong Kong or other
                      places, in public and/or by means of publication and
                      printings, web pages, electronics and other means that
                      BMWHK thinks fit, without prior notice or consent nor
                      indemnity to you.
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">8.</span>
                    <span>
                      These Terms and Conditions are prepared in English and the
                      Chinese translation is provided for reference only. In
                      case of any inconsistency or ambiguity, the English
                      version shall prevail.
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">9.</span>
                    <span>
                      BMW Concessionaires (HK) Ltd. reserves the right to amend
                      and cancel any details of the event without any prior
                      notice.
                    </span>
                  </li>
                  <li className="item flex">
                    <span className="mr-4">10.</span>
                    <span>
                      In case of disputes, the decision of BMW Concessionaires
                      (HK) Ltd. will be final.
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* <Swipeable
        // onSwiped={eventData => this.trackSwipeDirection(eventData.dir)}
        {...swipeConfig}
      >
        
      </Swipeable> */}
      <div
        className={`${
          sliderIsActive ? "opacity-1 visible" : "opacity-0 invisible"
        }`}
      >
        {sliderIsActive ? (
          <IGSlider
            thumbnail={sanity.thumbnail.childImageSharp.fluid}
            closeSlider={closeSlider}
            postContent={postContent}
            isChinese={isChinese}
          />
        ) : (
          ""
        )}
      </div>
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}
