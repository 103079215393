import React, { Component } from "react"
import Slider from "react-slick"
import ReactMarkdown from "react-markdown"
import BackgroundImage from "gatsby-background-image"
import styles from "./index.module.scss"
const htmlParser = require("react-markdown/plugins/html-parser")
export default class IGSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
    }
    const parseHtml = htmlParser({
      isValidNode: node => node.type !== "script",
    })
    const { isChinese, postContent } = this.props
    return (
      <div
        className={`fixed top-0 left-0 w-full h-full flex justify-center z-1000 ${styles.slider}`}
      >
        <div
          className="cross-container absolute right-0 top-0 p-8 cursor-pointer z-1000 xxl:p-16"
          onClick={() => this.props.closeSlider()}
        >
          <svg viewBox="0 0 612 612" className="w-10 text-white fill-current">
            <g>
              <g id="cross">
                <g>
                  <polygon
                    points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397 
				306,341.411 576.521,611.397 612,575.997 341.459,306.011 			"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="self-center w-8/12 md:w-full md:h-full z-50">
          <article className={`post ${styles.post}`}>
            <div className="flex h-full md:flex-col">
              <div className="w-8/12 h-full md:w-full md:h-1/2">
                <div className="ig-slider h-full">
                  <Slider {...settings} ref={slider => (this.slider = slider)}>
                    {postContent.images.map(image => (
                      <div className="slide h-full" key={image.src}>
                        <BackgroundImage
                          Tag="div"
                          className="w-full h-full"
                          fluid={image}
                          backgroundColor={`#040e18`}
                          loading="eager"
                          critical={true}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              <div className="w-4/12 md:w-full md:h-1/2">
                <div className="text-container bg-white p-12 h-full overflow-y-scroll">
                  <h5 className="body md:text-3xl bold-font mt-10 mb-16">
                    {isChinese ? postContent.cnTitle : postContent.enTitle}
                  </h5>
                  <div className="small-copy">
                    <ReactMarkdown
                      source={
                        isChinese ? postContent.cnFull : postContent.enFull
                      }
                      escapeHtml={false}
                      astPlugins={[parseHtml]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    )
  }
}
